<template>
	<main>
		<HeaderTab :title="$t('monte.contrat')"/>
		<div id="content">
			<div class="container-fluid">
				<div v-if="processing">
					<LoadingSpinner />
				</div>
				<div v-else class="pt-lg-5">
					<div class="row">
						<div class="col-12 col-lg-6">
							<div class="row">
								<div class="col-3">
									<label class="text-primary"><b>{{ $t('monte.numero_contrat') }} :</b></label>
								</div>
								<div class="col-8">
									#{{ contract.contract_num }}
								</div>
								<div class="col-3">
									<label class="text-primary"><b>{{ $t('monte.stallion') }} :</b></label>
								</div>
								<div class="col-8">
									{{ contract.contract_config.horse.horse_nom }}
								</div>
								<div class="col-3">
									<label class="text-primary"><b>{{ $t('monte.mare') }} :</b></label>
								</div>
								<div class="col-8">
									{{ contract.last_avenant.horse.horse_nom }}
								</div>
								<div class="col-3">
									<label class="text-primary"><b>{{ $t('monte.porteuse') }} :</b></label>
								</div>
								<div class="col-8">
									<template v-if="contract.last_avenant.porteuse">{{ contract.last_avenant.porteuse.horse_nom }}</template>
								</div>
								<div class="col-3">
									<label class="text-primary"><b>{{ $t('monte.cmep') }} :</b></label>
								</div>
								<div class="col-8">
									<template v-if="contract.last_avenant.cmep">{{ contract.last_avenant.cmep.tiers_rs }} {{ contract.last_avenant.cmep.tiers_postalcode }} {{ contract.last_avenant.cmep.tiers_town }}</template>
								</div>
								<div v-if="contract.contract_paillette > 0" class="col-3">
									<label class="text-primary"><b>{{ $t('monte.nombre_paillette') }} :</b></label>
								</div>
								<div v-if="contract.contract_paillette > 0" class="col-8">
									{{ contract.contract_paillette }}
								</div>
							</div>
						</div>
						<div class="col-12 col-lg-6">
							<div v-if="contract.season_mare_stallion" class="box rounded text-center">
								<b class="text-dark mb-3 d-block">{{ contract.season_mare_stallion.seasonmarestallion_status }}</b>
								<a :href="mailto" class="btn btn-primary"><font-awesome-icon :icon="['far', 'pencil-alt']"/> {{ $t('monte.report_dg_poulinage') }}</a>
							</div>
						</div>
					</div>
					<div class="row mt-lg-5">
						<div class="col-12 col-lg-8">
							<div class="d-none d-lg-block">&nbsp;</div><br/>
							<div class="row">
								<div class="col-6 col-md mb-4 mb-md-0">
									<div class="link_square" @click="quickPreview">
										<font-awesome-icon :icon="['fal', 'eye']" />
										{{ $t('monte.preview_contrat') }}
									</div>
								</div>
								<div class="col-6 col-md mb-4 mb-md-0" @click="prepareContract" v-if="contract.last_avenant.avenant_status == 1">
									<div class="link_square">
										<font-awesome-icon :icon="['fal', 'signature']" />
										{{ $t('monte.valider_contract') }}
									</div>
								</div>
								<div class="col-6 col-md mb-4 mb-md-0" @click="signatureContract" v-if="contract.last_avenant.avenant_status == 2 && hasSignatureAccess">
									<div class="link_square">
										<font-awesome-icon v-if="processing_signature" :icon="['fas', 'spinner']" pulse />
										<font-awesome-icon v-else :icon="['fal', 'signature']" />
										{{ $t('monte.signer_contract') }}
									</div>
								</div>
								<div class="col-6 col-md">
									<div class="link_square">
										<div v-if="contract.document_as" @click="downloadFile(contract.document_as, 'monte.attestation_saillie')">
											<font-awesome-icon :icon="['fal', 'download']" />
											{{ $t('monte.telechargement') }}
										</div>
										<div v-else>
											<font-awesome-icon :icon="['fal', 'clock']" />
											{{ $t('monte.bientot_dispo') }}
										</div>
										{{ $t('monte.attestation_saillie') }}
									</div>
								</div>
								<div class="col-6 col-md">
									<div class="link_square">
										<div v-if="contract.document_dn" @click="downloadFile(contract.document_dn, 'monte.declaration_naissance')">
											<font-awesome-icon :icon="['fal', 'download']" />
											{{ $t('monte.telechargement') }}
										</div>
										<div v-else>
											<font-awesome-icon :icon="['fal', 'clock']" />
											{{ $t('monte.bientot_dispo') }}
										</div>
										{{ $t('monte.declaration_naissance') }}
									</div>
								</div>
							</div>
						</div>
						<div class="col-12 col-lg-4 mt-3 mt-lg-0">
							<div class="row" id="marelist">
								<div class="col-12">
									<h5>{{ $t('monte.factures') }}</h5>
									<div class="row">
										<div v-for="invoice in invoices" class="col-12 mb-3" :key="invoice.invoice_id" @click.prevent="downloadInvoice(invoice)">
											<div class="mares mares_fiche d-flex">
												<span>
													<b>{{ invoice.invoice_num }}</b>
													<i>{{ invoice.invoice_ttc_format }}</i>
												</span>
												<span class="ml-auto">{{ invoice.invoice_balance_format }} {{ $t('monte.a_regler') }}</span>
												<a href="#"><font-awesome-icon :icon="['fal', 'download']"/></a>
											</div>
										</div>
									</div>
									<div class="text-right" v-if="total_int > 0">
										<b-button @click="prepareContract" variant="primary" class="mt-3"><font-awesome-icon :icon="['far', 'credit-card']"/> {{ $t('monte.payer') }} {{ total }}</b-button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<b-modal size="xl" ref="modelPreview" hide-footer @hidden="base64 = null">
            <template v-slot:modal-title>
                {{ $t("action.previsualiser") }}
            </template>

            <iframe v-if="base64 != null" :src="'data:application/pdf;base64,' + base64" height="1000px" width="100%" style="position:relative"></iframe>
            <div v-else>
                <LoadingSpinner />
            </div>
        </b-modal>

		<b-modal ref="modal" hide-footer>
			<template v-slot:modal-title>
				{{ $t("action.online_paiement") }}
			</template>

			<div v-if="!modal_loaded">
				<LoadingSpinner class="col-12" />
			</div>

			
			<div>
				
				<template v-if="marketplace == 'paybox'">
					<form @submit.prevent="payContract">

						<b-button class="mt-3" block @click.prevent="payContract">{{ $t("marketplace.payer") }}</b-button>
					</form>

					<!-- Formulaire caché, il sert a renvoyer vers atos grace a une arnaque moldave -->
					<form method="POST" :action="paybox_infos.url" id="redirectForm" class="d-none">
						<input type="hidden" name="PBX_SITE" :value="paybox_infos.PBX_SITE">
						<input type="hidden" name="PBX_RANG" :value="paybox_infos.PBX_RANG">
						<input type="hidden" name="PBX_IDENTIFIANT" :value="paybox_infos.PBX_IDENTIFIANT">
						<input type="hidden" name="PBX_TOTAL" :value="paybox_infos.PBX_TOTAL">
						<input type="hidden" name="PBX_DEVISE" :value="paybox_infos.PBX_DEVISE">
						<input type="hidden" name="PBX_RUF1" value="GET">
						<input type="hidden" name="PBX_CMD" :value="paybox_infos.PBX_CMD">
						<input type="hidden" name="PBX_PORTEUR" :value="paybox_infos.PBX_PORTEUR">
						<input type="hidden" name="PBX_REPONDRE_A" :value="paybox_infos.PBX_REPONDRE_A">
						<input type="hidden" name="PBX_RETOUR" :value="paybox_infos.PBX_RETOUR">
						<input type="hidden" name="PBX_EFFECTUE" :value="paybox_infos.PBX_EFFECTUE">
						<input type="hidden" name="PBX_ANNULE" :value="paybox_infos.PBX_ANNULE">
						<input type="hidden" name="PBX_REFUSE" :value="paybox_infos.PBX_REFUSE">
						<input type="hidden" name="PBX_HASH" value="SHA512">
						<input type="hidden" name="PBX_TIME" :value="paybox_infos.PBX_TIME">
						<input type="hidden" name="PBX_SHOPPINGCART" :value="paybox_infos.PBX_SHOPPINGCART">
						<input type="hidden" name="PBX_BILLING" :value="paybox_infos.PBX_BILLING">
						<input type="hidden" name="PBX_HMAC" :value="paybox_infos.PBX_HMAC">
						<button id="button_go_paybox" onclick="">valider</button>
					</form>
				</template>

				<template v-if="marketplace == 'sogecommerce'">
					<form @submit.prevent="payContract">

						<b-button class="mt-3" block @click.prevent="payContract">{{ $t("marketplace.payer") }}</b-button>
					</form>

					<!-- Formulaire caché, il sert a renvoyer vers atos grace a une arnaque moldave -->
					<form method="POST" :action="sogecommerce_infos.url" id="redirectForm" class="d-none">
						<input type="hidden" name="vads_action_mode" :value="sogecommerce_infos.vads_action_mode">
						<input type="hidden" name="vads_amount" :value="sogecommerce_infos.vads_amount">
						<input type="hidden" name="vads_ctx_mode" :value="sogecommerce_infos.vads_ctx_mode">
						<input type="hidden" name="vads_currency" :value="sogecommerce_infos.vads_currency">
						<input type="hidden" name="vads_page_action" :value="sogecommerce_infos.vads_page_action">
						<input type="hidden" name="vads_payment_config" :value="sogecommerce_infos.vads_payment_config">
						<input type="hidden" name="vads_site_id" :value="sogecommerce_infos.vads_site_id">
						<input type="hidden" name="vads_trans_date" :value="sogecommerce_infos.vads_trans_date">
						<input type="hidden" name="vads_trans_id" :value="sogecommerce_infos.vads_trans_id">
						<input type="hidden" name="vads_version" :value="sogecommerce_infos.vads_version">
						<input type="hidden" name="vads_payment_cards" :value="sogecommerce_infos.vads_payment_cards">
						<input type="hidden" name="vads_order_id" :value="sogecommerce_infos.vads_order_id">
						<input type="hidden" name="vads_cust_email" :value="sogecommerce_infos.vads_cust_email">
						<input type="hidden" name="vads_cust_id" :value="sogecommerce_infos.vads_cust_id">
						<input type="hidden" name="vads_cust_title" :value="sogecommerce_infos.vads_cust_title">
						<input type="hidden" name="vads_cust_status" :value="sogecommerce_infos.vads_cust_status">
						<input type="hidden" name="vads_cust_first_name" :value="sogecommerce_infos.vads_cust_first_name">
						<input type="hidden" name="vads_cust_last_name" :value="sogecommerce_infos.vads_cust_last_name">
						<input type="hidden" name="vads_cust_legal_name" :value="sogecommerce_infos.vads_cust_legal_name">
						<input type="hidden" name="vads_cust_phone" :value="sogecommerce_infos.vads_cust_phone">
						<input type="hidden" name="vads_cust_zip" :value="sogecommerce_infos.vads_cust_zip">
						<input type="hidden" name="vads_cust_city" :value="sogecommerce_infos.vads_cust_city">
						<input type="hidden" name="vads_cust_country" :value="sogecommerce_infos.vads_cust_country">
						<input type="hidden" name="signature" :value="sogecommerce_infos.signature">
						<button id="button_go_sogecommerce" onclick="">valider</button>
					</form>
				</template>
			</div>
		</b-modal>

		<b-modal ref="modalSignature" hide-footer>
            <template v-slot:modal-title>
                {{ $t("monte.lien_signature") }}
            </template>

            <div class="text-center">
            	<a :href="signature_link" class="btn btn-primary" target="_blank"><font-awesome-icon :icon="['far', 'pencil-alt']"/> {{ $t('monte.cliquer_ici_signature') }}</a>
            </div>
        </b-modal>
	</main>
</template>

<script type="text/javascript">
	import ContractMixin from "@/mixins/Contract.js"
	import InvoiceMixin from "@/mixins/Invoice.js"
	import Common from '@/assets/js/common.js'
    import Marketplace from '@/mixins/Marketplace.js'
	import UserMixin from "@/mixins/User.js"

	export default {
		name: 'ContractFiche',
		mixins: [ContractMixin, InvoiceMixin, Marketplace, UserMixin],
		data () {
			return {
				contract: {},
				contract_id: 0,
				processing: true,
				base64: null,
				invoices: [],
				total: 0,
				total_int: 0,
				modal_loaded: false,
				marketplace: 'mangopay',
				paybox_infos: {},
				sogecommerce_infos: {},
				signature_link: '',
				processing_signature: false
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				this.processing = true
				this.contract_id = this.$route.params.contract_id
				this.contract = await this.getContract(this.contract_id)

				let total = 0
				const invoices = await this.loadInvoiceContract(this.contract_id)
				if(invoices.length > 0) {
					for(let i in invoices) {
						invoices[i].invoice_balance_format = await Common.priceFormat(invoices[i].invoice_balance/100, invoices[i].tier.tiers_currency, true)
						invoices[i].invoice_ttc_format = await Common.priceFormat(invoices[i].invoice_ttc/100, invoices[i].tier.tiers_currency, true)
						total += invoices[i].invoice_balance/100
					}
					this.total_int = total
					total = await Common.priceFormat(total, invoices[0].tier.tiers_currency, true)
				}
				this.total = total
				this.invoices = invoices

				this.processing = false
			},

			downloadFile(uri, name) {
				const link = document.createElement("a")
				link.download = this.getTrad(name)
				link.href = uri
				link.target = '_blank'
				document.body.appendChild(link)
				link.click()
				document.body.removeChild(link)
			},

			async quickPreview() {
                this.$refs.modelPreview.show()
                let retour = await this.printContract(this.contract_id, true)
                if(retour) {
                    this.base64 = retour
                }
            },

            async downloadInvoice(invoice) {
            	await this.getAddressedPdfInvoice([invoice])
            },

			async prepareContract() {
				let marketplace = await this.getMarketplaceType()
           		this.marketplace = marketplace.retour.type

				this.modal_loaded = true
				this.contracts_to_pay = [this.contract.contract_id]
				this.$refs.modal.show()
			},

			async payContract() {
				let duedates_id = this.invoices.map(invoice => invoice.duedate.filter(duedate => duedate.duedate_balance !== 0).map(duedate => duedate.duedate_id)).flat(1)

				if(this.marketplace == 'paybox') {
					// let datas_payment = await this.redirectBoutiqueContract(this.contracts_to_pay)
                    let datas_payment = await this.redirectBoutique(duedates_id)

					if(datas_payment != null && datas_payment.retour.url != null)
					{
						this.paybox_infos =  datas_payment.retour
						if(this.paybox_infos.PBX_TOTAL == '0') {
							this.failureToast("toast.contract_montant_zero")
							this.$refs.modal.hide()
							return false
						}

						//A voir pour améliorer, le nextick ne fonctionne pas, ou alors j'arrive pas à l'utiliser correctement
						setTimeout(() => {
							document.querySelector("#button_go_paybox").click()
						},0)
					}
				}
				else if(this.marketplace == 'sogecommerce') {
					// let datas_payment = await this.redirectBoutiqueContract(this.contracts_to_pay)
                    let datas_payment = await this.redirectBoutique(duedates_id)

					if(datas_payment != null && datas_payment.retour.url != null)
					{
						this.sogecommerce_infos =  datas_payment.retour
						if(this.sogecommerce_infos.vads_amount == 0) {
							this.failureToast("toast.contract_montant_zero")
							this.$refs.modal.hide()
							return false
						}

						//A voir pour améliorer, le nextick ne fonctionne pas, ou alors j'arrive pas à l'utiliser correctement
						setTimeout(() => {
							document.querySelector("#button_go_sogecommerce").click()
						},0)
					}
				}
			},

			async signatureContract() {
				this.processing_signature = true
				const res = await this.generateContractSignatureLink(this.contract_id)
				if(res.success) {
					this.signature_link = res.url
					this.$refs.modalSignature.show()
				}
				this.processing_signature = false
			}
		},

		computed: {
			mailto() {
				return 'mailto:'+this.getUserCAMail()+'?subject='+this.getTrad('monte.mail_subject')+'&body='+this.getTrad('monte.mail_body')
			},

			hasSignatureAccess() {
            	return this.$store.state.userAccess.hasSignatureAccess
            },
		},

		components: {
			HeaderTab: () => import('GroomyRoot/components/HeaderTab'),
			LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35')
		}
	}

</script>